export const pushCompanyId = 'recWzDC0EqbXRuSXi';
export const newPushCompanyId = 'recuYaseTg1Y3N1Zo';
export const pullCompanyId = 'recaLDbwYgseZVsAQ';
export const ePullCompanyId = 'rec3zzmaPgXxdjaze';
export const waitingRoomId = 'waitingRoom';
export const simulationOverId = 'simulationOver';

export const formIds = {
  profit: 'rec7qlf1XpwYUxIOX',
  processTime: 'recYBvD7FHHWFcDnV',
  inspection: 'recC8Dlf4rM5a227O',
  inventory: 'rec1p8FtQrqwFFkaw',
  order: 'recnHj4QiDtw8Ossw',
  invoice: 'recQf7FvrKB3U7ak0',
};

export const productionRoles = {
  [pushCompanyId]: {
    name: 'Push',
    productionRoles: ['wireStripper', 'stakingPressOperator', 'assembler1', 'assembler2', 'solderer1', 'solderer2'],
  },
  [newPushCompanyId]: {
    name: 'New Push',
    productionRoles: [
      'wireStripper',
      'preSolderer',
      'stakingPressOperator',
      'assembler1',
      'assembler2',
      'solderer1',
      'solderer2',
    ],
  },
  [pullCompanyId]: {
    name: 'Pull',
    productionRoles: [
      'wireStripper',
      'preSolderer',
      'stakingPressOperator',
      'assembler1',
      'assembler2',
      'solderer1',
      'solderer2',
    ],
  },
  [ePullCompanyId]: {
    name: 'ePull',
    productionRoles: [
      'wireStripper',
      'preSolderer',
      'stakingPressOperator',
      'assembler1',
      'assembler2',
      'solderer1',
      'solderer2',
    ],
  },
};

export const permissionsKey = {
  all: 'All',
  admin: 'Admin',
  qa: 'Perform QA',
  customer: 'Approve Final Product',
  production: 'Production Floor Updates',
  accountant: 'Pay for Materials',
};

export const fieldNames = {
  roles: {
    roleName: {en: 'Role (en)', de: 'Role (de)'},
    uid: '__id',
    permissions: 'Permissions',
  },
  goals: {
    role: 'Role (en)',
    title: {en: 'Goal (en)', de: 'Ziel (de)'},
    description: {en: 'Description (en)', de: 'Beschreibung (de)'},
    company: 'Simulation',
    uid: '__id',
  },
  prompts: {
    company: 'Simulation',
    role: 'Role (en)',
    action: {en: 'Action (en)', de: 'Action (de)'},
    actionInfo: {en: 'Additional Info Actions (en)', de: 'Additional Info Actions (de)'},
    response: {en: 'Response (en)', de: 'Response (de)'},
    responseInfo: {en: 'Additional Info Responses (en)', de: 'Additional Info Responses (de)'},
    assets: 'Asset Reference',
    uid: '__id',
    minute: 'Minute',
  },
  assets: {
    title: {en: 'Name (EN)', de: 'Name (DE)'},
    adminName: 'Admin Name',
    medium: 'Medium',
    presentation: 'Presentation',
    srcArray: {en: 'Drive URL (EN)', de: 'Drive URL (DE)'},
    videoSrc: {en: 'URL (EN)', de: 'URL (DE)'},
    uiElements: 'UI Elements',
    uid: '__id',
    formEditRoleIds: 'Can fill out form',
  },
  companies: {
    name: 'Name',
    order: 'Order',
    uid: '__id',
    orgChart: {en: 'Drive URL (EN)', de: 'Drive URL (DE)'},
  },
  forms: {
    uid: 'formId',
  },
};

export const newPushMilestones = [
  {
    label: 'materials_prep',
    binsSquares: [
      {
        label: 'wires_cut',
        materialBaseName: 'preSolderedWires',
        feederRoles: ['wireStripper'],
      },
      {
        label: 'presoldered_wires',
        materialBaseName: 'strippedWires',
        feederRoles: ['preSolderer'],
      },
      {
        label: 'staked_boards',
        materialBaseName: 'stakedBoards',
        feederRoles: ['stakingPressOperator'],
      },
    ],
  },
  {
    label: 'board_assembly',
    binsSquares: [
      {
        label: 'assembled_boards',
        materialBaseName: 'assembledBoard',
        feederRoles: ['assembler1', 'assembler2'],
      },
    ],
  },
  {
    label: 'ready_for_qa',
    binsSquares: [
      {
        label: 'soldered_boards',
        materialBaseName: 'modelA',
        feederRoles: ['solderer1', 'solderer2'],
      },
    ],
  },
];

export const pullEPullMilestones = [
  {
    label: 'materials_prep',
    binsSquares: [
      {
        label: 'wires_cut',
        materialBaseName: 'strippedWireCommunicationSquare-preSolderedWires',
        feederRoles: ['wireStripper'],
      },
      {
        label: 'presoldered_wires',
        materialBaseName: 'preSolderedWireCommunicationSquare-strippedWires',
        feederRoles: ['preSolderer'],
      },
      {
        label: 'staked_boards',
        materialBaseName: 'stakedBoardCommunicationSquare-stakedBoards',
        feederRoles: ['stakingPressOperator'],
      },
    ],
  },
  {
    label: 'board_assembly',
    binsSquares: [
      {
        label: 'assembled_boards',
        materialBaseName: 'assembledBoardCommunicationSquare1-assembledBoards',
        feederRoles: ['assembler1'],
      },
      {
        label: 'assembled_boards',
        materialBaseName: 'assembledBoardCommunicationSquare2-assembledBoards',
        feederRoles: ['assembler2'],
      },
    ],
  },
  {
    label: 'ready_for_qa',
    binsSquares: [
      {
        label: 'soldered_boards',
        materialBaseName: 'finalInspectionCommunicationSquare-modelA',
        feederRoles: ['solderer1', 'solderer2'],
      },
    ],
  },
];

export const miscAdminButtons = [
  {
    label: 'cycle_time',
    preAction: 'start_cycle_time',
    postAction: 'started_cycle_time',
  },
  {
    label: 'stop_cycle_time',
    preAction: 'stop_cycle_time',
    postAction: 'stopped_cycle_time',
  },
  {
    label: 'red_wire_change',
    preAction: 'initiate_red_wire_change',
    postAction: 'initiated_red_wire_change',
  },
  {
    label: 'safety_wire_change',
    preAction: 'initiate_safety_wire_change',
    postAction: 'initiated_safety_wire_change',
  },
  {
    label: 'flip_staking_press_communication',
    preAction: 'flip_staking_press_communication',
    postAction: 'staking_press_communication_already_flipped',
  },
];

export const materials = ['black_wires', 'red_wires', 'boards', 'terminals'];

export const modelAAlertTypes = {
  qa: 'modelAQABatchAlert',
  customer: 'modelACustomerBatchAlert',
};

export const materialStatuses = ['raw_material', 'total_inventory'];
