export const newPushEntities = [
  {
    cols: 12,
    type: 'bin',
    label: 'raw_material',
    binsSquares: [
      {label: 'wires', materialBaseName: 'wires'},
      {label: 'terminals', materialBaseName: 'terminals'},
      {label: 'boards', materialBaseName: 'boards'},
    ],
  },
  {cols: 6, type: 'station', label: 'wireStripper', videoUrl: 'https://tathaminc.wistia.com/medias/cbtwehmfjs'},
  {
    cols: 6,
    rows: 4,
    type: 'station',
    label: 'stakingPressOperator',
    afterArrowLong: true,
    videoUrl: 'https://tathaminc.wistia.com/medias/gtb3syeajn',
  },
  {
    cols: 6,
    type: 'bin',
    label: 'stripped_wires',
    binsSquares: [{label: 'stripped_wires', materialBaseName: 'preSolderedWires'}],
  },
  {cols: 6},
  {cols: 6, type: 'station', label: 'preSolderer', videoUrl: 'https://tathaminc.wistia.com/medias/0ob7zd0mwu'},
  {
    cols: 12,
    type: 'bin',
    label: 'assembly_parts',
    binsSquares: [
      {label: 'wires_cut', materialBaseName: 'strippedWires'},
      {label: 'staked_boards', materialBaseName: 'stakedBoards'},
    ],
  },
  {cols: 6, type: 'station', label: 'assembler1', videoUrl: 'https://tathaminc.wistia.com/medias/jyzla2p2r9'},
  {cols: 6, type: 'station', label: 'assembler2', videoUrl: 'https://tathaminc.wistia.com/medias/jyzla2p2r9'},
  {
    cols: 12,
    type: 'bin',
    label: 'assembled_boards',
    binsSquares: [{label: 'assembled_boards', materialBaseName: 'assembledBoard'}],
  },
  {cols: 6, type: 'station', label: 'solderer1', videoUrl: 'https://tathaminc.wistia.com/medias/0ob7zd0mwu'},
  {cols: 6, type: 'station', label: 'solderer2', videoUrl: 'https://tathaminc.wistia.com/medias/0ob7zd0mwu'},
  {
    cols: 12,
    type: 'bin',
    label: 'ready_for_inspection',
    binsSquares: [{label: 'soldered_boards', materialBaseName: 'modelA'}],
  },
  {cols: 12, type: 'station', label: 'qa_inspector', notABot: true, activeIf: 'inQAModelA'},
  {cols: 12, type: 'bin', label: 'shipped', binsSquares: [{label: 'shipped', materialBaseName: 'shippedModelA'}]},
];
