import {pullEntities} from './pull';

// ePull is identical to pull except in "what do they do" videos.
const ePullEntities = JSON.parse(JSON.stringify(pullEntities));

// Wirestripper
ePullEntities[9].videoUrl = 'https://tathaminc.wistia.com/medias/oq0vcxbkrd';
// Staking Press Operator
ePullEntities[10].videoUrl = 'https://tathaminc.wistia.com/medias/plxbfwjq7z';
// Presolderer
ePullEntities[12].videoUrl = 'https://tathaminc.wistia.com/medias/zmrg0vwbzq';
// Assembler
ePullEntities[14].videoUrl = 'https://tathaminc.wistia.com/medias/l7336046jr';
ePullEntities[15].videoUrl = 'https://tathaminc.wistia.com/medias/l7336046jr';
// Solderer
ePullEntities[18].videoUrl = 'https://tathaminc.wistia.com/medias/coge4zs739';
ePullEntities[19].videoUrl = 'https://tathaminc.wistia.com/medias/coge4zs739';

export default ePullEntities;
