import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useDimensions from 'react-cool-dimensions';
import ReactPlayer from 'react-player/wistia';

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    width: '100%',
    position: 'relative',
    paddingTop: '56.25%',
  },
}));

export const Video = function ({url}) {
  // eslint-disable-next-line no-unused-vars
  const {observe, unobserve, width, height, entry} = useDimensions({
    onResize: ({observe, unobserve, width, height, entry}) => {
      // Triggered whenever the size of the target is changed...

      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.playerWrapper} ref={observe} style={{height: width * 0.55, minHeight: '360px'}}>
      <ReactPlayer
        url={url}
        controls
        playing
        width="100%"
        height="100%"
        style={{position: 'absolute', top: 0, left: 0}}
      />
    </div>
  );
};
