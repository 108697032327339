export const pushEntities = [
  {
    cols: 12,
    type: 'bin',
    label: 'raw_material',
    binsSquares: [
      {label: 'wires', materialBaseName: 'wires'},
      {label: 'terminals', materialBaseName: 'terminals'},
      {label: 'boards', materialBaseName: 'boards'},
    ],
  },
  {
    cols: 6,
    type: 'station',
    label: 'wireStripper',
    afterArrowLong: true,
    videoUrl: 'https://tathaminc.wistia.com/medias/am4vqdtdtl',
  },
  {cols: 6, type: 'station', label: 'stakingPressOperator', videoUrl: 'https://tathaminc.wistia.com/medias/gtb3syeajn'},
  {
    cols: 12,
    type: 'bin',
    label: 'assembly_parts',
    binsSquares: [
      {label: 'wires_cut', materialBaseName: 'strippedWires'},
      {label: 'staked_boards', materialBaseName: 'stakedBoards'},
    ],
  },
  {cols: 6, type: 'station', label: 'assembler1', videoUrl: 'https://tathaminc.wistia.com/medias/jyzla2p2r9'},
  {cols: 6, type: 'station', label: 'assembler2', videoUrl: 'https://tathaminc.wistia.com/medias/jyzla2p2r9'},
  {
    cols: 12,
    type: 'bin',
    label: 'assembled_boards',
    binsSquares: [{label: 'assembled_boards', materialBaseName: 'assembledBoard'}],
  },
  {cols: 6, type: 'station', label: 'solderer1', videoUrl: 'https://tathaminc.wistia.com/medias/1ifn4v5pr5'},
  {cols: 6, type: 'station', label: 'solderer2', videoUrl: 'https://tathaminc.wistia.com/medias/1ifn4v5pr5'},
  {
    cols: 12,
    type: 'bin',
    label: 'ready_for_inspection',
    binsSquares: [{label: 'soldered_boards', materialBaseName: 'modelA'}],
  },
  {cols: 12, type: 'station', label: 'qa_inspector', notABot: true, activeIf: 'inQAModelA'},
  {cols: 6, type: 'station', label: 'shipper', notABot: true, activeIf: 'shippedModelA', hideBeforeArrow: true},
  {
    cols: 6,
    type: 'bin',
    label: 'needs_rework',
    binsSquares: [{label: 'needs_rework', materialBaseName: 'inReworkModelA'}],
  },
  {
    cols: 6,
    type: 'bin',
    label: 'shipped',
    binsSquares: [{label: 'shipped', materialBaseName: 'shippedModelA'}],
  },
  {
    cols: 6,
    type: 'station',
    label: 'rework_operator',
    videoUrl: 'https://tathaminc.wistia.com/medias/p926hu35ew',
    notABot: true,
    activeIf: 'inReworkModelA',
    hideAfterArrow: true,
    style: {marginTop: -30},
  },
];
