import React from 'react';
import ImageGallery from 'react-image-gallery';

export const Gallery = function ({airtableAttachments, alt}) {
  const preparedImages = airtableAttachments?.map((url) => {
    // Step 1: Get GoogleDrive file id from https://drive.google.com/file/d/184_JhwXMg16jm3kBjKlDthPhAEMyrvWo/view.
    const driveId = getStringBetween(url, 'file/d/', '/view');
    if (driveId) {
      // Step 2: Set url to pattern https://drive.google.com/thumbnail?id=184_JhwXMg16jm3kBjKlDthPhAEMyrvWo&sz=w1000
      url = `https://drive.google.com/thumbnail?id=${driveId}\&sz=w1000`;
    }
    return {
      original: url,
      thumbnail: url,
      originalAlt: alt,
      thumbnailAlt: alt,
    };
  });
  return (
    <ImageGallery
      items={preparedImages}
      showPlayButton={false}
      showIndex={preparedImages?.length > 1}
      showThumbnails={preparedImages?.length > 1}
    />
  );
};

function getStringBetween(str, start, end) {
  const result = str.match(new RegExp(start + '(.*)' + end));

  return result?.[1];
}
