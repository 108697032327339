export const pullEntities = [
  {
    cols: 12,
    type: 'station',
    label: 'supplier',
    notABot: true,
    hideBeforeArrow: true,
    hideAfterArrow: true,
    activeIf: 'null',
  },
  {cols: 6, type: 'arrow'},
  {cols: 6, type: 'arrow'},
  {cols: 6, type: 'bin', label: 'wires', binsSquares: [{materialBaseName: 'rawMaterialCommunicationSquare'}]},
  {cols: 2},
  {cols: 1, type: 'bin', label: 'terminals', binsSquares: [{materialBaseName: 'rawMaterialCommunicationSquare'}]},
  {cols: 1, type: 'bin', label: 'terminals', binsSquares: [{materialBaseName: 'rawBoardCommunicationSquare'}]},
  {cols: 2},
  {cols: 6, type: 'station', label: 'wireStripper', videoUrl: 'https://tathaminc.wistia.com/medias/cbtwehmfjs'},
  {
    cols: 6,
    rows: 3,
    type: 'station',
    label: 'stakingPressOperator',
    afterArrowLong: true,
    videoUrl: 'https://tathaminc.wistia.com/medias/sjl1oh2l38',
  },
  {
    cols: 6,
    type: 'bin',
    label: 'stripped_wires',
    binsSquares: [{materialBaseName: 'preSolderedWireCommunicationSquare'}],
  },
  {cols: 6, type: 'station', label: 'preSolderer', videoUrl: 'https://tathaminc.wistia.com/medias/3r8j6iqiuz'},
  {
    cols: 6,
    type: 'bin',
    label: 'staked_board',
    binsSquares: [{materialBaseName: 'stakedBoardCommunicationSquare'}],
  },

  {
    cols: 6,
    type: 'bin',
    label: 'presoldered_wire',
    binsSquares: [{materialBaseName: 'strippedWireCommunicationSquare'}],
  },

  {cols: 6, type: 'station', label: 'assembler1', videoUrl: 'https://tathaminc.wistia.com/medias/zobmt343ld'},
  {cols: 6, type: 'station', label: 'assembler2', videoUrl: 'https://tathaminc.wistia.com/medias/zobmt343ld'},
  {
    cols: 6,
    type: 'bin',
    label: 'assembled_boards',
    binsSquares: [{materialBaseName: 'assembledBoardCommunicationSquare1'}],
  },
  {
    cols: 6,
    type: 'bin',
    label: 'assembled_boards',
    binsSquares: [{materialBaseName: 'assembledBoardCommunicationSquare2'}],
  },
  {cols: 6, type: 'station', label: 'solderer1', videoUrl: 'https://tathaminc.wistia.com/medias/mrtdkhx4my'},
  {cols: 6, type: 'station', label: 'solderer2', videoUrl: 'https://tathaminc.wistia.com/medias/mrtdkhx4my'},
  {
    cols: 12,
    type: 'bin',
    label: 'ready_for_inspection',
    binsSquares: [{materialBaseName: 'finalInspectionCommunicationSquare'}],
  },
  {cols: 12, type: 'station', label: 'process_auditor', notABot: true, activeIf: 'inQAModelA'},
  {
    cols: 12,
    type: 'bin',
    label: 'shipped',
    binsSquares: [{label: 'shipped', materialBaseName: 'shippingCommunicationSquare'}],
  },
];
